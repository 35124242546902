/* global fetch */
import { convertFromCustomFormat } from '@wix/cloud-elementory-protocol'
export default class DataFetcher {
  constructor({ getRequestParams }) {
    this.getRequestParams = getRequestParams
  }

  async _makeRequestTo(endpoint, body) {
    const { instance, gridAppId } = this.getRequestParams()

    const response = await fetch(
      `/_serverless/data-binding-server/${endpoint}?gridAppId=${gridAppId}`,
      {
        method: 'POST',
        headers: {
          Authorization: instance,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    )
    return response.json()
  }

  async fetchInitialData(datasetConfigs) {
    const { recordsByCollection, recordsInfoByDataset } =
      await this._makeRequestTo('fetch-initial-data', datasetConfigs)
    const convertedResponse = {
      recordsInfoByDataset,
      recordsByCollection: {},
    }

    for (const [collectionId, collection] of Object.entries(
      recordsByCollection,
    )) {
      const convertedCollection = (convertedResponse.recordsByCollection[
        collectionId
      ] = {})
      for (const [recordId, record] of Object.entries(collection)) {
        convertedCollection[recordId] = convertFromCustomFormat(record)
      }
    }
    return convertedResponse
  }

  async fetchData(requestConfig) {
    const { items, ...rest } = await this._makeRequestTo(
      'fetch-data',
      requestConfig,
    )
    return {
      ...rest,
      items: items.map(item => convertFromCustomFormat(item)),
    }
  }

  async remove(requestConfig) {
    return this._makeRequestTo('remove', requestConfig)
  }

  async save(requestConfig) {
    const { item } = await this._makeRequestTo('save', requestConfig)
    return convertFromCustomFormat(item)
  }
}
