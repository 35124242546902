'use strict'

import '../helpers/polyfills'
import {
  get,
  noop,
  pick,
  uniq,
  without,
  isEmpty,
  flatten,
  difference,
  find,
} from 'lodash-es'
import parseUrl from 'url-parse'
import DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import { convertFromCustomFormat } from '@wix/cloud-elementory-protocol'
import { parseUrlPattern } from '@wix/dbsm-common/src/dynamic-pages/urlUtils'
import schemaAPICreator from '../schemas/schemaAPI'
import getReferencedCollectionsNames from '../schemas/getReferencedCollectionsNames'
import wixDataProxyCreator from '../wix-data/wixDataProxy'
import { createRecordStoreService } from '../record-store'
import createControllerFactory from '../dataset-controller/controllerFactory'
import createDatabindingVerboseReporter from '../verbose/databindingVerboseReporter'
import { traceCreators } from '../logger'
import { TraceType } from '../logger/traceType'
import {
  isEnvEditor,
  isModePreview,
  isModeLivePreview,
} from '../helpers/viewMode'
import { nullVerboseReporter } from '../dataset-api/verbosity'
import Deferred from '../helpers/Deferred'
import DataProvider from '../data/DataProvider'
import DataFetcher from '../data/DataFetcher'
import LegacyDataFetcher from '../data/LegacyDataFetcher'
import DataStore from '../data/DataStore'
import TempFesClientImpl from '../data/TempFesClientImpl'
import WixDataProvider from '../data/WixDataProvider'
import completeControllerConfigs from '../dataset-controller/completeControllerConfigs'

const getSDK = controllerConfigs => controllerConfigs[0].wixCodeApi
const isSSRMode = controllerConfigs =>
  get(getSDK(controllerConfigs), ['window', 'rendering', 'env']) === 'backend'
const getViewMode = controllerConfigs =>
  get(getSDK(controllerConfigs), ['window', 'viewMode'])

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {},
  )

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  dynamicUrl,
  record,
  sortFields,
  patternFields,
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick(record, sortAndPatternFields) : null
}

const getDatasetSortFields = sort =>
  flatten(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const extractRouterPayload = (payload, parser, controllerConfigs) => {
  const routerDataset = find(controllerConfigs, {
    type: DATASET_TYPES.ROUTER_DATASET,
  })
  const datasetId = routerDataset && routerDataset.compId
  if (!datasetId) return {}
  const collectionName = get(routerDataset, 'config.dataset.collectionName')

  const { dynamicUrl, userDefinedFilter, items, totalCount, config } = payload
  const parsedItems = parser(items)
  const record = parsedItems ? parsedItems[0] : null
  const datasetSort = get(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields),
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]

  const dynamicUrlPatternFieldsValues =
    extractDynamicUrlPatternFieldsValuesFromRecord(
      dynamicUrl,
      record,
      sortFields,
      patternFields,
    )

  return {
    routerData: {
      items: parsedItems,
      totalCount,
      datasetId,
      collectionId: collectionName,
    },
    dynamicPagesData: {
      dynamicUrl,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues,
      sort,
      sortFields,
      patternFields,
    },
  }
}

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose,
})

export default ({
  wixDataCreator,
  getElementorySupport,
  errorReporter,
  verboseReporter: originalVerboseReporter,
  shouldVerbose,
  appLogger,
  automationsClientCreator,
}) => {
  let wixDataProxy
  let wixDataSchemasProxy
  let routerPayload
  let schemaAPI
  let wixSdk
  let experiments
  let recordStoreCache
  let verboseReporter
  let automationsClient

  const startLoadingSchemas = (controllerConfigs, modeIsSSR) => {
    const prefetchedSchemaData = null

    if (prefetchedSchemaData) {
      return schemaAPI.loadPrefetched(prefetchedSchemaData)
    }

    const collectionIds = getMainCollectionIds(controllerConfigs)

    if (
      collectionIds.length === 1 &&
      hasAllSchemasReferencedByCollection(
        collectionIds[0],
        routerPayload.schemas,
      )
    ) {
      return schemaAPI.loadPrefetched(routerPayload.schemas)
    }

    return appLogger
      .traceAsync(traceCreators.loadSchemas(), () =>
        schemaAPI.loadSchemas(collectionIds),
      )
      .catch(() => {})
  }

  const hasAllSchemasReferencedByCollection = (collectionId, schemas) => {
    if (isEmpty(schemas)) {
      return false
    }

    const mainSchema = schemas[collectionId]
    if (isEmpty(mainSchema)) {
      return false
    }

    const referencedCollectionIds = getReferencedCollectionsNames(mainSchema)
    return (
      isEmpty(referencedCollectionIds) ||
      referencedCollectionIds.every(cid => !isEmpty(schemas[cid]))
    )
  }

  const getMainCollectionIds = controllerConfigs => {
    const ids = controllerConfigs.map(controllerConfig =>
      get(
        controllerConfig,
        [
          'config',
          'dataset',
          'collectionName', // is actually collectionId :(
        ],
        null,
      ),
    )
    return without(uniq(ids), null)
  }

  const app = {
    initAppForPage: (
      { routerReturnedData },
      _,
      _wixSdk,
      {
        bi = {},
        reportTrace = noop,
        monitoring: { createMonitor: createRavenClient },
        fedOpsLoggerFactory,
        biLoggerFactory,
        essentials,
      } = {},
    ) => {
      try {
        wixSdk = _wixSdk
        experiments = essentials.experiments
        recordStoreCache = {}

        const modeIsSsr =
          get(wixSdk, ['window', 'rendering', 'env']) === 'backend'
        const viewMode = get(wixSdk, ['window', 'viewMode'])
        verboseReporter =
          shouldVerbose && isModePreview(viewMode)
            ? originalVerboseReporter
            : nullVerboseReporter

        appLogger.addSessionData(() => ({ routerReturnedData }))
        appLogger.init({
          appLogger,
          user: {
            id: get(wixSdk, ['user', 'currentUser', 'id']),
          },
          inSsr: modeIsSsr,
          viewMode,
          platformBiParams: bi,
          browserUrlGetter: () => get(wixSdk, ['location', 'url']),
          reportTrace,
          createRavenClient,
          fedOpsLoggerFactory,
          biLoggerFactory,
        })

        appLogger.traceSync(traceCreators.initAppForPage(), () => {
          ;({ wixDataProxy, wixDataSchemasProxy } = wixDataProxyCreator(
            appLogger.wixDataCodeZone,
            () => {
              // centralized experiment conduction is currently supported in live sites only
              // so isDataNamespaceEnabled will always be false in editor, preview and bolt
              const isDataNamespaceEnabled = experiments.enabled(
                'specs.wixDataViewer.UseDataNamespace',
              )

              return wixDataCreator({
                baseUrl: wixSdk.location.baseUrl,
                envIsEditor: isEnvEditor(viewMode),
                platformizedWixData: isDataNamespaceEnabled && wixSdk.data,
              })
            },
          ))
          routerPayload = routerReturnedData || {}
          schemaAPI = schemaAPICreator(wixDataSchemasProxy, appLogger)
          automationsClient = automationsClientCreator(global.elementorySupport)
        })
        return Promise.resolve()
      } catch (e) {
        appLogger.error(e)
        return Promise.reject(e)
      }
    },
    createControllers: rawControllerConfigs => {
      return appLogger.traceSync(traceCreators.createControllers(), () => {
        try {
          if (rawControllerConfigs.length === 0) {
            return []
          }

          const controllerConfigs = completeControllerConfigs(
            rawControllerConfigs,
            routerPayload,
          )
          const viewMode = getViewMode(controllerConfigs)
          const modeIsSSR = isSSRMode(controllerConfigs)

          startLoadingSchemas(controllerConfigs, modeIsSSR)

          const reportFormEventToAutomation =
            automationsClient.reportFormEventToAutomationCreator({
              isPreview: isEnvEditor(viewMode),
            })

          const instansiateDatabindingVerboseReporter =
            createDatabindingVerboseReporter(verboseReporter, shouldVerbose)
          const renderingControllers = []
          const {
            resolve: renderDeferredControllers,
            promise: renderingRegularControllers,
          } = Deferred()

          const dataFetcher = experiments.enabled(
            'specs.wixDataViewer.EnableFES',
          )
            ? new DataFetcher({
                getRequestParams: () => {
                  const {
                    query: { instance, gridAppId },
                  } = parseUrl(
                    `?${getElementorySupport().queryParameters}`,
                    true,
                  )
                  return { instance, gridAppId }
                },
              })
            : new LegacyDataFetcher({
                server: new TempFesClientImpl(
                  new WixDataProvider(wixDataProxy, schemaAPI),
                ),
              })

          const warmupData = {}
          const { routerData, dynamicPagesData } = extractRouterPayload(
            routerPayload,
            convertFromCustomFormat,
            controllerConfigs,
          )
          const dataStore = new DataStore(warmupData, routerData)
          const dataProvider = new DataProvider({
            dataFetcher,
            dataStore,
            datasetConfigs: controllerConfigs.map(
              ({
                compId: datasetId,
                config: {
                  datasetStaticConfig: { sequenceType },
                },
              }) => ({ datasetId, sequenceType }),
            ),
            //TODO: remove loggers
            appLogger,
            errorReporter,
          })

          const controllers = controllerConfigs.map(
            ({
              type,
              config,
              connections,
              $w,
              compId: datasetId,
              livePreviewOptions: {
                shouldFetchData: dataIsInvalidated,
                compsIdsToReset: updatedCompIds = [],
              } = {},
              platformAPIs,
              wixCodeApi: wixSdk,
            }) => {
              appLogger.trace(
                TraceType.Breadcrumb({
                  level: 'info',
                  category: 'createControllers',
                  message: 'warmup data contents',
                  data: {
                    datasetId,
                    datasetType: type,
                    env: get(wixSdk, ['window', 'rendering', 'env']),
                    warmupData: false,
                  },
                }),
              )

              const recordStoreService = createRecordStoreService({
                primaryDatasetId: datasetId,
                recordStoreCache,
                refreshStoreCache: dataIsInvalidated,
                warmupStore: undefined,
                dataProvider,
                controllerConfig: config,
                logger: appLogger,
              })

              const {
                promise: renderingController,
                resolve: markControllerAsRendered,
              } = Deferred()

              renderingControllers.push(renderingController)

              const controllerFactory = createControllerFactory(appLogger, {
                $w,
                controllerConfig: config,
                datasetType: type,
                connections,
                recordStoreService,
                wixDataProxy,
                firePlatformEvent: appLogger.userCodeZone($w.fireEvent),
                wixSdk,
                errorReporter,
                verboseReporter,
                instansiateDatabindingVerboseReporter,
                dynamicPagesData:
                  type === DATASET_TYPES.ROUTER_DATASET
                    ? dynamicPagesData
                    : undefined,
                appLogger,
                datasetId,
                handshakes: [],
                schemaAPI,
                reportFormEventToAutomation,
                platformAPIs,
                updatedCompIds,
                markControllerAsRendered,
                renderingRegularControllers,
                // isModeLivePreview is ture only if the LivePreview feature is enabled,
                // since in other case the Viewer won't be loaded at all
                modeIsLivePreview: isModeLivePreview(viewMode),
                modeIsSSR,
              })

              const datasetController = extractPlatformControllerAPI(
                controllerFactory.createPrimaryController(),
              )
              return Promise.resolve(datasetController)
            },
          )

          Promise.all(renderingControllers).then(renderDeferredControllers)

          return controllers
        } catch (e) {
          appLogger.error(e)
          return []
        }
      })
    },
  }

  return app
}
