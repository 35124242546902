import Deferred from '../helpers/Deferred'
import { PRIMARY } from './sequenceType'
import { traceCreators } from '../logger'

class DataProvider {
  constructor({
    dataStore,
    dataFetcher,
    datasetConfigs,
    appLogger,
    errorReporter,
  }) {
    this.dataFetcher = dataFetcher
    this.dataStore = dataStore

    this.primaryDatasetInitializationPromises = []
    this.primaryDatasetInitializerById = datasetConfigs.reduce(
      (acc, { datasetId, sequenceType }) => {
        if (sequenceType === PRIMARY) {
          const { promise, resolve } = Deferred()
          this.primaryDatasetInitializationPromises.push(promise)
          acc[datasetId] = { resolve, requestConfig: {} }
        }
        return acc
      },
      {},
    )

    this.primaryDatasetInitialDataFetching = Promise.all(
      this.primaryDatasetInitializationPromises,
    ).then(async datasetConfigs => {
      // get warmup or
      const {
        recordsByCollection,
        recordsInfoByDataset: recordsInfoByDatasetWithErrors,
      } = await appLogger.traceAsync(
        // TODO: move to proxy!!
        traceCreators.fetchPrimaryInitialData(),
        () => this.dataFetcher.fetchInitialData(datasetConfigs),
      )

      const recordsInfoByDataset = recordsInfoByDatasetWithErrors.reduce(
        (acc, { itemIds = [], totalCount = 0, error }, index) => {
          if (error) {
            const { message, originalError } = error
            appLogger.error(originalError)
            errorReporter(message, originalError)
          }
          const { datasetId } = datasetConfigs[index]
          acc[datasetId] = { itemIds, totalCount }
          return acc
        },
        {},
      )

      this.dataStore.cacheInitialData({
        recordsByCollection,
        recordsInfoByDataset,
      })

      return {
        recordsByCollection,
        recordsInfoByDataset,
      }
    })
  }

  async getInitialData({
    datasetId,
    collectionId,
    filter,
    sort,
    length,
    includes,
  }) {
    if (this.primaryDatasetInitializerById[datasetId]) {
      // TODO: reject and return cache data if we have it
      this.primaryDatasetInitializerById[datasetId].resolve({
        datasetId,
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
      })
      await this.primaryDatasetInitialDataFetching
      return this.dataStore.getCachedData({
        datasetId,
        collectionId,
        includes,
      })
    }
    const cachedData = this.dataStore.getCachedData({
      datasetId,
      collectionId,
      includes,
    })

    return (
      cachedData ||
      this.getData({
        collectionId,
        filter,
        sort,
        offset: 0,
        length,
        includes,
      })
    )
  }

  async getData({ collectionId, filter, sort, offset, length, includes }) {
    const data = await this.dataFetcher.fetchData({
      collectionId,
      filter,
      sort,
      offset,
      length,
      includes,
    })
    this.dataStore.cacheData({ collectionId, data })
    return data
  }

  getRecordById({ collectionId, recordId, includes }) {
    //not used for now
    return this.dataStore.getCachedRecord({
      collectionId,
      recordId,
      includes,
    })
  }

  async remove({ collectionId, recordId }) {
    return this.dataFetcher.remove({
      collectionId,
      recordId,
    })
  }

  async save({ collectionId, record, includeReferences }) {
    return this.dataFetcher.save({
      collectionId,
      record,
      includeReferences,
    })
  }
}

export default DataProvider
