import { mergeWith, isPlainObject, get } from 'lodash-es'
import { mergeReferences } from './utils'

export default class DataStore {
  constructor(warmupData = {}, routerData = {}) {
    const { recordsInfoByDataset, recordsByCollection } = initStoreWithData(
      warmupData,
      routerData,
    )

    this.recordsInfoByDataset = recordsInfoByDataset
    this.recordsByCollection = recordsByCollection
  }

  getCachedData({ datasetId, collectionId, includes }) {
    const data = this.recordsInfoByDataset[datasetId]
    return data
      ? {
          totalCount: data.totalCount,
          items: data.itemIds.map(recordId =>
            hideIrrelevantRefs(
              this.recordsByCollection[collectionId][recordId],
              includes,
            ),
          ),
        }
      : null
  }

  getCachedRecord({ collectionId, recordId, includes }) {
    const record = get(this.recordsByCollection, [collectionId, recordId], null)
    if (!record) return getEmptyResponse()
    return {
      totalCount: 1,
      items: [
        hideIrrelevantRefs(
          this.recordsByCollection[collectionId][recordId],
          includes,
        ),
      ],
    }
  }

  cacheData({ collectionId, data }) {
    const { items } = data

    /* copy paste from fes */
    this.recordsByCollection[collectionId] = mergeItemsToMap(
      items,
      this.recordsByCollection[collectionId],
    )
  }

  cacheInitialData({ recordsByCollection, recordsInfoByDataset }) {
    this.recordsInfoByDataset = recordsInfoByDataset

    Object.entries(recordsByCollection).forEach(
      ([collectionId, recordsById]) => {
        this.recordsByCollection[collectionId] = { ...recordsById }
      },
    )
  }
}

const isValueReference = value => isPlainObject(value) && Boolean(value._id)

const isReferenceExcluded = (includes, fieldName) =>
  !includes || !includes.includes(fieldName)

const hideIrrelevantRefs = (record, includes) => {
  return Object.entries(record).reduce((acc, [fieldName, value]) => {
    if (isValueReference(value) && isReferenceExcluded(includes, fieldName)) {
      acc[fieldName] = value._id
    } else {
      acc[fieldName] = value
    }
    return acc
  }, {})
}

const mergeItemsToMap = (items, map) =>
  items.reduce((acc, record) => {
    const existingRecord = acc[record._id]
    acc[record._id] = existingRecord
      ? mergeWith(existingRecord, record, mergeReferences)
      : record

    return acc
  }, map || {})

const initStoreWithData = (
  { recordsInfoByDataset = {}, recordsByCollection = {} },
  { datasetId, items = [], totalCount, collectionId },
) => {
  return {
    recordsInfoByDataset: {
      ...recordsInfoByDataset,
      ...(datasetId
        ? {
            [datasetId]: {
              itemIds: items.map(({ _id }) => _id),
              totalCount,
            },
          }
        : {}),
    },
    recordsByCollection: {
      ...recordsByCollection,
      ...(datasetId
        ? {
            [collectionId]: mergeItemsToMap(
              items,
              recordsByCollection[collectionId],
            ),
          }
        : {}),
    },
  }
}

const getEmptyResponse = () => ({
  totalCount: 0,
  items: [],
})
