import { mergeWith } from 'lodash-es'
import { mergeReferences } from './utils'

class TempFesClientImpl {
  constructor(dataProvider) {
    this.dataProvider = dataProvider
  }

  _aggregateData(datasetConfigs, datasetsData) {
    return datasetsData.reduce(
      (response, { items, totalCount, error, message }, index) => {
        if (error) {
          response.recordsInfoByDataset.push({
            error: {
              message,
              originalError: error,
            },
          })
          return response
        }
        response.recordsInfoByDataset.push({
          itemIds: items.map(({ _id }) => _id),
          totalCount,
        })
        const collectionId = datasetConfigs[index].collectionId
        response.recordsByCollection[collectionId] = items.reduce(
          (acc, record) => {
            const existingRecord = acc[record._id]
            acc[record._id] = mergeWith(existingRecord, record, mergeReferences)

            return acc
          },
          response.recordsByCollection[collectionId] || {},
        )

        return response
      },
      { recordsInfoByDataset: [], recordsByCollection: {} },
    )
  }

  async fetchInitialData(datasetConfigs) {
    const datasetsData = await Promise.all(
      datasetConfigs.map(
        async ({ collectionId, filter, sort, offset, length, includes }) => {
          try {
            return await this.dataProvider.get({
              collectionId,
              filter,
              sort,
              offset,
              length,
              includes,
            })
          } catch (error) {
            return { error, message: new Error('Failed to load initial data') }
          }
        },
      ),
    )

    return this._aggregateData(datasetConfigs, datasetsData)
  }

  async fetchData({ collectionId, filter, sort, offset, length, includes }) {
    return this.dataProvider.get({
      collectionId,
      filter,
      sort,
      offset,
      length,
      includes,
    })
  }

  async remove({ collectionId, recordId }) {
    return this.dataProvider.remove({
      collectionId,
      recordId,
    })
  }

  async save({ collectionId, record, includeReferences }) {
    return this.dataProvider.save({
      collectionId,
      record,
      includeReferences,
    })
  }
}

export default TempFesClientImpl
